<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4 offset-md-4">
        <img alt="Qizuj" src="@/assets/logo_qizuj.png" class="w-75 mt-0 mb-3">
        <Toast position="top-right" />
        <Message
          v-for="msg of messages"
          :severity="msg.severity"
          :key="msg.content"
          class="my-2"
          >{{ msg.content }}
        </Message>

        <suspense>
          <component v-bind:is="comp"></component>
          <template #fallback>
            <div>
              <div class="splash">
                <div class="m-4">
                  <img
                    src="@/assets/logo_stohl.png"
                    alt="Vzdělávací středisko Ing. Pavla Štohla"
                  />
                </div>
                <div class="spinner-border text-warning" role="status"></div>
                <p class="text-light">Nahrávám data ...</p>
              </div>
            </div>
          </template>
        </suspense>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import Toast from "primevue/toast";
import Message from "primevue/message";
import { useFullscreen } from '@vueuse/core'

const { isFullscreen, enter, exit, toggle } = useFullscreen()
const LOGIN_URL = "/student/login"
const QUESTION_URL = "/student/question/"
const ANSWER_URL = "/student/answer/"

export default {
  name: 'App',
  components: {
    Toast,
    Message,
    Login: defineAsyncComponent(() => import('./components/Login.vue')),
    QuestionWaiting:defineAsyncComponent(() => import('./components/QuestionWaiting.vue')),
    Question:defineAsyncComponent(() => import('./components/Question.vue')),
    QuestionResult:defineAsyncComponent(() => import('./components/QuestionResult.vue')),
    GameOver:defineAsyncComponent(() => import('./components/GameOver.vue')),
  },
  props: ["pin", "testsetID"],
  data() {
    return {
      messages: [],
      comp:"Login"
    };
  },
  mounted() {},
  beforeUnmount(){},
  methods: {
    login(student){
      this.axios.post(LOGIN_URL, student).then((response) => {
        this.$store.commit('setStudent', response.data)
        enter()
        this.comp = "QuestionWaiting"
      }).catch( (error) => {
        if(error.response.status == 401){
          this.messages.push({ severity: "error", content: "Neplatný PIN." });
          return
        }
        console.error(error);
        this.messages.push({ severity: "error", content: error });
      })
    },
    question(){
      this.axios.get( QUESTION_URL + this.$store.state.student?._id).then((response) => {
        this.$store.commit('setQuestion', response.data)
        this.comp = "Question"
      }).catch( (error) => {
        if(error.response.status == 403){
          this.$toast.add({severity:'info', summary: 'Vyčkejte', detail:`Ještě nemůžete odpovídat.`, life: 3000});
          this.comp = "QuestionWaiting"
          return
        }

        if(error.response.status == 405){
          this.comp = "GameOver"
          return
        }

        if(error.response.status == 406){
          this.$toast.add({severity:'info', summary: 'Odpověď už byla odeslaná.', detail:`Počkejte na další otázku.`, life: 3000});
          this.comp = "QuestionWaiting"
          return
        }

        console.error(error);
        this.messages.push({ severity: "error", content: error });
      })
    },
    answer(idx){
      this.axios.get(`${ANSWER_URL}${this.$store.state.student._id}/${idx}`).then((response) => {
        this.$store.commit('setStudent', response.data)
        this.comp = "QuestionResult"
      }).catch( (error) => {
        if(error.response.status == 403){
          this.$toast.add({severity:'info', summary: 'Vyčkejte prosím.', detail:`Otázka ještě nebyla zveřejněna.`, life: 3000});
          return
        }
        if(error.response.status == 406){
          this.$toast.add({severity:'info', summary: 'Odpověď už byla odeslaná.', detail:`Počkejte na další otázku.`, life: 3000});
          return
        }

        console.error(error);
        this.messages.push({ severity: "error", content: error });
      })
    },

    restart(){
      setTimeout(() => {
        location.replace( location.href.split('#')[0] )
      }, 1000)
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}

.bg-0 {
  background-color: #e31e24;
}
.bg-1 {
  background-color: #009846;
}
.bg-2 {
  background-color: #ef7f1a;
}
.bg-3 {
  background-color: #ae4a84;
}
.bg-4 {
  background-color: #008dd2;
}
.bg-5 {
  background-color: #264796;
}
</style>
