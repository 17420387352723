import { createStore } from 'vuex'

export default createStore({
  state: {
    student:undefined,
    question:undefined
  },
  getters: {
  },
  mutations: {
    setStudent( state, student){
      state.student = student
    },
    setQuestion(state, question){
      state.question = question
    }
  },
  actions: {
  },
  modules: {
  }
})
