import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import VueAnimXyz from '@animxyz/vue3'
import '@animxyz/core'

createApp(App,{})
.use(store)
.use(VueAxios, axios)
.use(PrimeVue, {ripple: true})
.use(ToastService)
.use(VueAnimXyz)
.mount('#app')
